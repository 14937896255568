import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { useIsGettingFinance } from "../../hooks/account.hooks";
import { usePropertyList } from "../../hooks/property.hooks";
import { isNotEmptyObject } from "../../utils/object-util";
import { titleCase } from "../../utils/text-format";
import AddPropertyButton from "./add-property-button";
import EditPropertyDialog from "./edit-property-dialog";
import JointAccountContext from "../../context/joint-account-context";

const LinkedPropertyAccount = ({
  addressDetails = {},
  accountId = "",
  propertyId = "",
  loanListData = [],
  propertyAttribute = {},
  handleBackdropStatus = () => {},
  isUnUpdate = false,
  cursor = false,
}) => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  const { selectedPropertyId = "", setSelectedPropertyId } = useContext(
    SelectedPropertyContext
  );
  const {
    data: propertyList,
    isLoading: isLoadingPropertyList,
  } = usePropertyList();
  const gettingFinance = useIsGettingFinance();
  const handleClickProperty = () => {
    if (cursor && propertyId !== selectedPropertyId) {
      setSelectedPropertyId(propertyId);
      handleBackdropStatus(true);
    }
  };
  const {
    locality = "",
    postcode = "",
    state = "",
    street = "",
    streetNumber = "",
    unitNumber = "",
    streetNumberHigh = "",
  } = addressDetails;
  const address = `${unitNumber ? `${unitNumber} / ` : ""}${streetNumber}${
    streetNumberHigh ? ` - ${streetNumberHigh}` : ""
  } ${street} Street,`;
  const suburb = `${titleCase(locality)}, ${state}, ${postcode}`;
  return (
    <div
      className={classNames("la-container is-cursor", {
        selected: selectedPropertyId && selectedPropertyId === propertyId,
      })}
      role="button"
      onClick={handleClickProperty}
      onKeyPress={() => {}}
      tabIndex="0"
      id={propertyId}
    >
      {!selectedJointAccount &&
        isNotEmptyObject(addressDetails) &&
        !isUnUpdate && (
          <EditPropertyDialog propertyAttribute={propertyAttribute} />
        )}
      <div className="property-account-container">
        {isNotEmptyObject(addressDetails) && (
          <div className="property-item-wrapper">
            <div className="property-address">
              {titleCase(address)} {suburb}
            </div>
          </div>
        )}
        {loanListData.length > 0 &&
          loanListData.map((item) => {
            const {
              accountId: loanAccountId,
              institutionLogo: logo = "",
              name = "",
              balance: value = 0,
              ownerName = "",
            } = item;
            return (
              <div className="linked-account-wrapper" key={loanAccountId}>
                <div className="la-logo">
                  {gettingFinance ? (
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={50}
                      height={50}
                    />
                  ) : (
                    logo && <img src={logo} alt="bank-logo" />
                  )}
                </div>
                <div className="linked-account-item-value">
                  {Boolean(ownerName) && selectedJointAccount && (
                    <div className="linked-account-owner">
                      {ownerName}&apos;s Account
                    </div>
                  )}
                  <div>
                    {gettingFinance ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width="70%"
                        height={20}
                      />
                    ) : (
                      name
                    )}
                  </div>
                  {gettingFinance ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="50%"
                      height={20}
                    />
                  ) : (
                    value !== 0 && (
                      <NumberFormat
                        value={value}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    )
                  )}
                </div>
              </div>
            );
          })}
        {!selectedJointAccount &&
          !isNotEmptyObject(addressDetails) &&
          (!isLoadingPropertyList || propertyList?.length > 0) && (
            <AddPropertyButton accountId={accountId} title="Link Property" />
          )}
      </div>
    </div>
  );
};

export default LinkedPropertyAccount;

LinkedPropertyAccount.propTypes = {
  addressDetails: PropTypes.object,
  accountId: PropTypes.string,
  propertyId: PropTypes.string,
  loanListData: PropTypes.array,
  propertyAttribute: PropTypes.object,
  handleBackdropStatus: PropTypes.func,
  isUnUpdate: PropTypes.bool,
  cursor: PropTypes.bool,
};
