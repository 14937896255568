import PropTypes from "prop-types";
import React, { useState } from "react";
import { BathRoom, BedRoom, Car, LandArea } from "../../svgs";
import PriceEstimateIcon from "./price-estimate-icon";
import UpdatePropertyModal from "./update-property-modal";

const PropertyValueAttribute = ({ propertyAttribute = {} }) => {
  const {
    propertyType = "",
    bathrooms = 0,
    bedrooms = 0,
    carparks = 0,
    landarea = 0,
  } = propertyAttribute;

  const [
    isOpenUpdatePropertyComponent,
    setIsOpenUpdatePropertyComponent,
  ] = useState(false);
  const handleOpenUpdateProperty = () => {
    setIsOpenUpdatePropertyComponent(true);
  };

  const handleCloseUpdateProperty = () => {
    setIsOpenUpdatePropertyComponent(false);
  };
  return (
    <div className="property-value-attribute">
      <div className="property-value-title">Attributes</div>
      <div>Property Type: {propertyType}</div>
      <div className="property-value-icon-group">
        <PriceEstimateIcon logo={<BedRoom />} number={bedrooms} />
        <PriceEstimateIcon logo={<BathRoom />} number={bathrooms} />
        <PriceEstimateIcon logo={<Car />} number={carparks} />
        <PriceEstimateIcon logo={<LandArea />} number={landarea} />
      </div>
      <div className="property-value-text">
        Is this correct?{" "}
        <span className="text-underline" onClick={handleOpenUpdateProperty}>
          Update property
        </span>
      </div>
      <UpdatePropertyModal
        open={isOpenUpdatePropertyComponent}
        handleDialogClose={handleCloseUpdateProperty}
        propertyAttribute={propertyAttribute}
      />
    </div>
  );
};

PropertyValueAttribute.propTypes = {
  propertyAttribute: PropTypes.shape({
    propertyType: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    carparks: PropTypes.number,
    landarea: PropTypes.number,
  }).isRequired,
};

export default PropertyValueAttribute;
