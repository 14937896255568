import PropTypes from "prop-types";
import React from "react";

const MarketIndicatorProgressItem = ({
  firstTitle = "",
  secondTitle = "",
  value = 0,
}) => {
  return (
    <div className="market-indicator-progress-container">
      <div className="linear-progress">
        <div className="linear-progress-tick" style={{ left: `${value}%` }} />
      </div>
      <div className="market-indicator-progress-wrapper">
        <div className="market-indicator-progress-item">
          <div>{firstTitle}</div>
        </div>
        <div className="market-indicator-progress-item">
          <div>{secondTitle}</div>
        </div>
      </div>
    </div>
  );
};

MarketIndicatorProgressItem.propTypes = {
  firstTitle: PropTypes.string,
  secondTitle: PropTypes.string,
  value: PropTypes.number,
};

export default MarketIndicatorProgressItem;
