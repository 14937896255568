import { withStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { ThreeDot } from "../../images";
import RemovePropertyButton from "./remove-property-button";
import UnlinkProperty from "./unlink-property";
import UpdatePropertyButton from "./update-property-button";

const StyledMenuItem = withStyles({
  root: {
    fontSize: 14,
    fontFamily: "ProximaNovaRegular",
    justifyContent: "center",
    textAlign: "center",
  },
})(MenuItem);

const EditPropertyDialog = ({ propertyAttribute }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [isShowUnlink, setIsShowUnlink] = useState(false);
  const { selectedProperty } = useContext(SelectedPropertyContext);

  const handleDialogToggle = (value) => {
    setOpenMenu(value);
  };
  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  useEffect(() => {
    setIsShowUnlink(selectedProperty?.accountIds || "");
  }, [selectedProperty]);

  return (
    <>
      <div onClick={handleClick} className="three-dots-icon">
        <img src={ThreeDot} alt="" />
      </div>
      <Menu
        id="long-menu"
        anchorEl={openMenu}
        keepMounted
        open={openMenu}
        onClose={() => handleDialogToggle(false)}
        PaperProps={{
          style: {
            fontFamily: "ProximaNovaRegular",
            fontSize: 14,
            width: "20ch",
          },
        }}
      >
        <StyledMenuItem>
          <UpdatePropertyButton
            handleEditDialogToggle={handleDialogToggle}
            propertyAttribute={propertyAttribute}
          />
        </StyledMenuItem>
        {isShowUnlink && (
          <StyledMenuItem>
            <UnlinkProperty handleEditDialogToggle={handleDialogToggle} />
          </StyledMenuItem>
        )}
        <StyledMenuItem className="remove-property">
          <RemovePropertyButton handleEditDialogToggle={handleDialogToggle} />
        </StyledMenuItem>
      </Menu>
    </>
  );
};

EditPropertyDialog.propTypes = {
  propertyAttribute: PropTypes.object,
};

export default React.memo(EditPropertyDialog);
