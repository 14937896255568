import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { putProperty } from "../../api/property";
import PropertyMontlyRentContext from "../../context/property-monthly-rent-context";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { usePropertyList } from "../../hooks/property.hooks";

const styles = () => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: 50,
  },
  textField: {
    margin: 0,
    width: "100%",
    color: "black",
    opacity: 1,
    borderBottom: 0,
    "&:before": {
      borderBottom: 0,
    },
    "& input": {
      textAlign: "center",
      fontFamily: "ProximaNovaRegular",
      fontSize: 14,
      padding: 0,
    },
  },
  disabled: {
    color: "black",
    borderBottom: 0,
    "&:before": {
      borderBottom: 0,
    },
  },
  btnIcons: {
    marginLeft: 10,
  },
});

const EditableTextField = ({ classes, fieldName = "monthlyRent" }) => {
  const { refetch: refetchPropertyList } = usePropertyList();
  const { selectedPropertyId } = useContext(SelectedPropertyContext);
  const monthlyValue = useContext(PropertyMontlyRentContext);
  const value = monthlyValue[fieldName] || 0;
  const formRef = useRef();
  const [editFieldState, setEditFieldState] = useState({
    editMode: false,
    mouseOver: false,
  });

  const handleMouseOver = () => {
    if (!editFieldState.mouseOver) {
      setEditFieldState({
        ...editFieldState,
        mouseOver: true,
        editMode: true,
      });
    }
  };

  const handleMouseOut = () => {
    if (editFieldState.mouseOver) {
      setEditFieldState({
        ...editFieldState,
        mouseOver: false,
        editMode: false,
      });
    }
  };

  const handleClick = () => {
    setEditFieldState({
      ...editFieldState,
      editMode: true,
      mouseOver: false,
    });
  };

  const handleOnBlur = () => {
    setEditFieldState({ ...editFieldState, editMode: false });
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  return (
    <>
      {!editFieldState.editMode && !editFieldState.mouseOver ? (
        <div
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
          onClick={handleClick}
        >
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </div>
      ) : (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              [fieldName]: value,
            }}
            validate={(values) => {
              const errors = {};
              if (isNaN(values[fieldName])) {
                errors[fieldName] = "Required";
              }
              return errors;
            }}
            onSubmit={(values) => {
              const params = {
                [fieldName]: values[fieldName],
              };
              putProperty(params, selectedPropertyId).finally(() => {
                refetchPropertyList();
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  name={fieldName}
                  value={values[fieldName]}
                  margin="normal"
                  type="number"
                  onChange={handleChange}
                  disabled={!editFieldState.editMode}
                  className={classes.textField}
                  onMouseEnter={handleMouseOver}
                  onMouseLeave={handleMouseOut}
                  onBlur={handleOnBlur}
                  onClick={handleClick}
                  helperText={
                    errors[fieldName] && touched[fieldName] && errors[fieldName]
                  }
                  error={errors[fieldName] && touched[fieldName]}
                />
              </form>
            )}
          </Formik>
        </MuiPickersUtilsProvider>
      )}
    </>
  );
};

export default withStyles(styles)(EditableTextField);
