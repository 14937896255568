import PropTypes from "prop-types";
import React from "react";
import {
  formatNumberWithDollar,
  formatNumberWithPercent,
} from "../../utils/numberFormater";

const ComponentPropertyCashflowItem = ({
  title = "",
  value = 0,
  percent = false,
}) => {
  return (
    <div className="combined-cashflow-item">
      <div className="combined-cashflow-title">{title}</div>
      <div className="combined-cashflow-value">
        {percent
          ? formatNumberWithPercent(value)
          : formatNumberWithDollar(value)}
      </div>
    </div>
  );
};

ComponentPropertyCashflowItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  percent: PropTypes.bool,
};

export default ComponentPropertyCashflowItem;
