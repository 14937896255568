import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { ESTIMATION_TYPES, TIME_FILTER_INDICES } from "../commons/enum";
import { useIsTablet } from "../hooks/common.hooks";
import {
  usePropertyList,
  usePropertyNearbyDetail,
  usePropertyPriceHistory,
} from "../hooks/property.hooks";
import { primaryColor } from "../scss/colors.scss";
import { monthFormater } from "../utils/calendar-util";
import { isNonZeroDataPoint, tooltipsBaseOption } from "../utils/chart-util";
import { formatNumber, formatNumberWithText } from "../utils/numberFormater";
import ChartYearFilter from "./chart-year-filter";
import LoadingChartBackdrop from "./loading-chart-backdrop";

const buildChartOption = (isMobile) => {
  return {
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          type: "time",
          offset: true,
          beginAtZero: false,
          display: true,
          time: {
            displayFormats: {
              month: "YYYY-MM",
            },
          },
          gridLines: {
            display: true,
            drawOnChartArea: false,
          },
          ticks: {
            maxTicksLimit: isMobile ? 2 : 15,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            beginAtZero: false,
            source: "auto",
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: false,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
        },
        {
          ticks: {
            padding: 5,
            maxTicksLimit: 6,
            callback: (value) => formatNumberWithText(value, 2),
          },
          type: "linear",
          display: !isMobile,
          position: "left",
          id: "y-axis-2",
          gridLines: {
            drawBorder: false,
            lineWidth: 0.75,
            display: false,
          },
          labels: {
            show: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: "bottom",
    },
    tooltips: {
      ...tooltipsBaseOption,
      callbacks: {
        label(tooltipItem, data) {
          const xLabel = data.datasets[tooltipItem.datasetIndex].label;
          const { yLabel } = tooltipItem;
          const chartValueWithCommas = formatNumber(Math.abs(yLabel));
          return `${xLabel}: ${
            yLabel >= 0
              ? `$${chartValueWithCommas}`
              : `-$${chartValueWithCommas}`
          } `.trim();
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };
};

const buildChartData = (
  chartDataset,
  debtData = {},
  suburbData = [],
  estimationType,
  selectedTimeFilterIndex
) => {
  const { time = [], value: chartValue = [] } = chartDataset;
  const chartTime = [...time]
    .reverse()
    .map((item) => monthFormater(new Date(item)));
  const chartTimeRange15Year = suburbData.map((suburbItem) => {
    return monthFormater(new Date(suburbItem?.x));
  });

  const fullTimeRange = [...new Set([...chartTimeRange15Year, ...chartTime])];
  const selectedTimeRange =
    selectedTimeFilterIndex === TIME_FILTER_INDICES.ALL &&
    estimationType !== ESTIMATION_TYPES.PROPERTY_PORTFOLIO
      ? fullTimeRange
      : chartTime;

  const debtChart = selectedTimeRange
    .map((t) => {
      return {
        x: t,
        y: debtData[t] || 0,
      };
    })
    .filter(isNonZeroDataPoint);

  const propertyEstimateChartData = [...chartValue]
    .reverse()
    .map((value, index) => ({
      x: chartTime[index],
      y: value,
    }))
    .filter(isNonZeroDataPoint);

  const suburbChartData = selectedTimeRange
    .map((t) => {
      const subb = suburbData.find(
        (data) => monthFormater(new Date(data.x)) === t
      );
      return {
        x: t,
        y: subb?.y,
      };
    })
    .filter(isNonZeroDataPoint);

  const filterAllLineChartData =
    selectedTimeFilterIndex === TIME_FILTER_INDICES.ALL
      ? suburbData
      : suburbChartData;
  const lineChartData =
    estimationType === ESTIMATION_TYPES.SUBURB_AVERAGE
      ? filterAllLineChartData
      : propertyEstimateChartData;

  const lineChartDataset = {
    label:
      estimationType === ESTIMATION_TYPES.SUBURB_AVERAGE
        ? "Suburb Average"
        : "Estimate Value",
    type: "line",
    data: lineChartData,
    fill: false,
    borderColor: "#131C24",
    backgroundColor: "#131C24",
    pointBorderColor: "#131C24",
    pointBackgroundColor: "#131C24",
    pointHoverBackgroundColor: "#131C24",
    pointHoverBorderColor: "#131C24",
    pointRadius: 0,
    yAxisID: "y-axis-2",
  };

  const lineChartDataset2 = {
    label: "Suburb Average",
    type: "line",
    data:
      selectedTimeFilterIndex === TIME_FILTER_INDICES.ALL
        ? suburbData
        : suburbChartData,
    fill: false,
    borderColor: primaryColor,
    backgroundColor: primaryColor,
    pointBorderColor: primaryColor,
    pointBackgroundColor: primaryColor,
    pointHoverBackgroundColor: primaryColor,
    pointHoverBorderColor: primaryColor,
    pointRadius: 0,
    yAxisID: "y-axis-2",
  };

  const debtChartDataset = {
    label: "Debt",
    data: debtChart,
    fill: false,
    backgroundColor: "#E9EDF2",
    borderColor: "#E9EDF2",
    hoverBackgroundColor: "#E9EDF2",
    hoverBorderColor: "#E9EDF2",
    barPercentage: 0.5,
    yAxisID: "y-axis-2",
  };
  let chartDatasets = [lineChartDataset];
  if (debtChart.length) {
    chartDatasets = [...chartDatasets, debtChartDataset];
  }
  if (estimationType === ESTIMATION_TYPES.COMBINED) {
    chartDatasets = [...chartDatasets, lineChartDataset2];
  }
  const chartData = {
    labels:
      selectedTimeFilterIndex === TIME_FILTER_INDICES.ALL &&
      estimationType !== ESTIMATION_TYPES.PROPERTY_PORTFOLIO
        ? fullTimeRange
        : chartTime,
    datasets: chartDatasets,
  };

  return chartData;
};

const MixedChartComponent = ({
  hideChartFilter = false,
  defaultTimeFilterIndex = TIME_FILTER_INDICES.ONE_YEAR,
  defaultEstimationType = ESTIMATION_TYPES.PROPERTY_PORTFOLIO,
  propertyEstimateHistory,
  saleHistoryChartData,
  debtChartData,
}) => {
  const isTablet = useIsTablet();
  const { isLoading: isLoadingPropertyList } = usePropertyList();
  const {
    isLoading: isLoadingPropertyPriceHistory,
  } = usePropertyPriceHistory();
  const {
    isLoading: isLoadingPropertyNearbyDetail,
  } = usePropertyNearbyDetail();
  const [chartData, setChartData] = useState({});
  const [selectedEstimationType, setSelectedEstimationType] = useState(
    defaultEstimationType
  );
  const [selectedTimeFilterIndex, setSelectedTimeFilterIndex] = useState(
    defaultTimeFilterIndex
  );
  useEffect(() => {
    setSelectedTimeFilterIndex(defaultTimeFilterIndex);
  }, [defaultTimeFilterIndex]);
  const isLoading =
    isLoadingPropertyList ||
    isLoadingPropertyPriceHistory ||
    isLoadingPropertyNearbyDetail;
  const options = buildChartOption(isTablet);
  useEffect(() => {
    if (propertyEstimateHistory) {
      const years = Object.values(propertyEstimateHistory);
      const selectedYear = years[selectedTimeFilterIndex];
      if (selectedYear) {
        setChartData(selectedYear);
      } else setChartData(years[selectedTimeFilterIndex - 1]);
    } else {
      setChartData({});
    }
  }, [selectedTimeFilterIndex, propertyEstimateHistory]);

  const mixChartData = useMemo(
    () =>
      buildChartData(
        chartData,
        debtChartData,
        saleHistoryChartData,
        selectedEstimationType,
        selectedTimeFilterIndex
      ),
    [
      selectedEstimationType,
      selectedTimeFilterIndex,
      chartData,
      debtChartData,
      saleHistoryChartData,
    ]
  );

  return (
    <div className="fullwidth-chart-container">
      {!hideChartFilter && (
        <div className="header-with-monthpicker">
          <ChartYearFilter
            selectedIndex={selectedTimeFilterIndex}
            setSelectedIndex={setSelectedTimeFilterIndex}
            selectedEstimate={selectedEstimationType}
            setSelectedEstimate={setSelectedEstimationType}
            isEstimate
          />
        </div>
      )}
      <div className="bar-chart-size">
        <Bar data={mixChartData} options={options} height={null} width={null} />
        {isLoading && (
          <div className="loading-chart-indicator">
            <LoadingChartBackdrop loadingState={isLoading} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MixedChartComponent;
