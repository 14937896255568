import React from "react";

const OutlinedDiv = ({ children, label }) => {
  return (
    <div className="outline-div">
      <div className="outline-div-label-container">
        <span className="outline-div-label">{label}</span>
      </div>
      <div className="outline-div-body">{children}</div>
    </div>
  );
};
export default OutlinedDiv;
