import React from "react";
import DoughnutChartComponent from "../../components/doughnut-chart-component";
import OutlinedDiv from "../../components/out-line-div";
import { convertToInt } from "../../utils/convert-number";
import PerformanceScoreCard from "./performance-score-card-item";

const CashflowPositionComponent = ({ cashflowData = {} }) => {
  const { monthlyRent = 0, monthlyRepayment = 0 } = cashflowData;
  const totalValue = monthlyRent + monthlyRepayment;
  const chartPercent = (monthlyRent / totalValue) * 100;
  const chartData = {
    data: [
      monthlyRent,
      monthlyRepayment === 0 && monthlyRent === 0 ? 1 : monthlyRepayment,
    ],
    title: ["Rental Income", "Repayment"],
  };
  return (
    <OutlinedDiv label="Cashflow Postion">
      <div className="d-flex justify-content-between">
        <div className="cashflow-position-item">
          <PerformanceScoreCard
            dollar
            firstTitle="Monthly Rent"
            secondTitle="Monthly Repayment"
            thirdTitle="Excess Income"
            firstValue={monthlyRent}
            secondValue={monthlyRepayment}
          />
        </div>
        <div className="cashflow-position-chart">
          <DoughnutChartComponent
            title={`Cashflow ${
              monthlyRent > monthlyRepayment ? "Positive" : "Negative"
            }`}
            data={chartData}
            textTop={`${convertToInt(chartPercent)}%`}
            textBottom="Rental Income"
          />
        </div>
      </div>
    </OutlinedDiv>
  );
};

export default CashflowPositionComponent;
