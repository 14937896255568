import PropTypes from "prop-types";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.scss";
import "slick-carousel/slick/slick.scss";
import { AngleLeft, AngleRight } from "../../images";
import ComparablePropertiesItemComponent from "./comparable-properties-item-component";

const ComparablePropertiesContainer = ({ propertyListing = [] }) => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={AngleRight} alt="" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={AngleLeft} alt="" />
      </div>
    );
  }
  const settings = {
    customPaging() {
      return <div className="custom-dots" />;
    },
    dotsClass: "slick-dots custom-slick-dots",
    dots: false,
    infinite: false,
    fadeIn: false,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: false,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="comparable-properties">
      <div className="comparable-properties-title">
        <div className="d-inline-block">
          Comparable Properties
          <div className="text-divider " />
        </div>
      </div>
      <div className="comparable-properties-item-container">
        {propertyListing.length && (
          <Slider {...settings}>
            {propertyListing.map((item) => {
              const {
                propertyId = "",
                images = [],
                address = "",
                bathrooms = 0,
                bedrooms = 0,
                carparks = 0,
                landarea = 0,
                saleDate = "",
                salePrice = 0,
              } = item;
              const propertyAttribute = {
                bathrooms,
                bedrooms,
                carparks,
                landarea,
              };
              return (
                <ComparablePropertiesItemComponent
                  key={`comparablePropertyItem${propertyId}`}
                  address={address}
                  image={images}
                  value={salePrice}
                  propertyAttribute={propertyAttribute}
                  saleDate={saleDate}
                />
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};

ComparablePropertiesContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  propertyListing: PropTypes.array,
};

export default React.memo(ComparablePropertiesContainer);
