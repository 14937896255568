/* eslint-disable no-param-reassign */
import { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import { PROPERTY_PURPOSE } from "../../commons/enum";
import {
  mobileChartSliderSettings,
  mobileSliderSettings,
} from "../../commons/slider-mobile-setting";
import { PageBody, PageBodyContent } from "../../components";
import ConnectBankModal, {
  DefaultAddProperty,
} from "../../components/connect-bank-modal";
import HeaderComponent from "../../components/HeaderComponent/header-component";
import { LineChartMobileWrapper } from "../../components/line-chart-mobile-wrapper";
import MixedChartComponent from "../../components/mixed-chart-component";
import HandleDialogContext from "../../context/handle-dialog-context";
import PropertyMontlyRentContext from "../../context/property-monthly-rent-context";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import CashflowPositionComponent from "../../features/property/cashflow-position-component";
import CashflowPositionMobileComponent from "../../features/property/cashflow-position-mobile-component";
import CombinedPropertyComponent from "../../features/property/combined-property-component";
import ComparablePropertiesContainer from "../../features/property/comparable-properties-container";
import LinkedProperty from "../../features/property/linked-property";
import LinkedPropertyCarousel from "../../features/property/linked-property-carousel";
import MarketIndicatorProgressItem from "../../features/property/market-indicator-progress-component";
import PerformanceScoreCardComponent from "../../features/property/performance-score-card-component";
import PerformanceScoreCard from "../../features/property/performance-score-card-item";
import PropertyDebtPosition from "../../features/property/property-debt-position";
import PropertyValuationComponent from "../../features/property/property-valuation-component";
import PropertyValueAttribute from "../../features/property/property-value-attribute";
import PropertyValuePriceEstimate from "../../features/property/property-value-price-estimate";
import RemovePropertyComponent from "../../features/property/remove-property-component";
import { useIsDesktopSmall, useIsTablet } from "../../hooks/common.hooks";
import {
  useDetailPropertyList,
  useMarketIndices,
  usePropertyDebtList,
  useTotalMarketIndices,
  useTotalPropertyDebtData,
  useTotalPropertyDetail,
} from "../../hooks/property.hooks";
import "../../scss/property.scss";
import {
  buildFilteredPropertyChart,
  buildSuburbFilteredData,
} from "../../utils/calculate-property-data-util";
import { monthFormater } from "../../utils/calendar-util";
import { convertToFloat, convertToInt } from "../../utils/convert-number";
import { getByPathOrDefault } from "../../utils/expensesUtils";
import { titleCase } from "../../utils/text-format";
import DateFilter from "./date-filter";
import propertyVideo from "../../videos/properties.mp4";

const initialPropertyList = [];

const buildMarketData = ({
  marketIndices,
  propertyType,
  locality,
  postcode,
}) => {
  if (locality !== "" && postcode !== "") {
    const marketIndi = getByPathOrDefault(
      marketIndices,
      [
        propertyType.toLowerCase(),
        "series",
        `${locality}-${postcode}`,
        "marketscore",
      ],
      []
    );
    const { dates: marketDates = [] } = marketIndices || {};
    const marketPrices = getByPathOrDefault(
      marketIndices,
      [
        propertyType.toLowerCase(),
        "series",
        `${locality}-${postcode}`,
        "price",
      ],
      []
    );
    const getMarketIndicatorData = marketIndi
      .slice()
      .reduce((result, marketItem) => {
        if (marketItem) {
          return marketItem;
        }
        return result;
      }, 0);

    return {
      marketDates,
      marketPrices,
      marketIndicatorData: getMarketIndicatorData,
    };
  }
  return {
    marketDates: [],
    marketPrices: [],
    marketIndicatorData: 0,
  };
};

const buildSaleHistoryChartData = ({ marketDates, marketPrices }) => {
  if (marketPrices.length > 0 && marketDates.length > 0) {
    return marketPrices.reduce((saleChart, item, index) => {
      if (item) {
        const chartData = {
          x: monthFormater(new Date(marketDates[index])),
          y: item,
        };
        saleChart.push(chartData);
      }
      return saleChart;
    }, []);
  }
  return [];
};

const buildPropertyItemData = ({
  selectedPropertyDebtData,
  selectedProperty = {},
}) => {
  const {
    currentDebt = 0,
    history = {},
    monthlyRepayment: propertyMonthlyRepayment,
  } = selectedPropertyDebtData;
  const {
    confidence = "",
    estimate = 0,
    estimateHigh = 0,
    estimateLow = 0,
    propertyType = "",
    bathrooms = 0,
    bedrooms = 0,
    carparks = 0,
    landarea = 0,
    purpose = "",
    last12MnthsLocalityDataSimilarProperties = {},
    monthlyRent = 0,
    monthlyRepayment = 0,
  } = selectedProperty;
  const {
    meanPercentRentalYield = 0,
    medianPrice = 0,
  } = last12MnthsLocalityDataSimilarProperties;
  const rentalYield = (monthlyRent * 1200) / estimate;
  const _monthlyRepayment =
    convertToInt(monthlyRepayment) ||
    convertToInt(propertyMonthlyRepayment) ||
    0;
  const propertyAttribute = {
    confidence,
    estimate,
    estimateHigh,
    estimateLow,
    propertyType,
    bathrooms,
    bedrooms,
    carparks,
    landarea,
    purpose,
    medianPrice,
    currentDebt,
    monthlyRepayment: _monthlyRepayment,
    monthlyRent: monthlyRent || 0,
  };
  const performanceScoreCard = {
    medianPrice,
    meanPercentRentalYield,
    averageMonthlyRent: convertToInt(
      (medianPrice * meanPercentRentalYield) / 1200
    ),
    estimate,
    percentRentYieldEstimate: convertToFloat(rentalYield, 1),
    monthlyRent: monthlyRent || 0,
  };
  const cashflowData = {
    monthlyRent: monthlyRent || 0,
    monthlyRepayment: _monthlyRepayment,
  };
  return {
    propertyAttribute,
    performanceScoreCard,
    debtChartData: history,
    cashflowData,
  };
};

const buildCombinedPropertyCashflowPosition = ({
  totalPropertyDebtData,
  propertyPortfolio,
  totalPropertyDetail,
}) => {
  const {
    estimate: portfolioValue = 0,
    monthlyRent: portfolioMonthlyRent = 0,
    averageYield = 0,
    arrayLength = 1,
  } = propertyPortfolio;
  const {
    monthlyRepayment: totalMonthlyRepayment = 0,
    currentDebt: totalCurrentDebt = 0,
    history: totalDebtHistory = {},
  } = totalPropertyDebtData;
  return {
    totalDebtHistory,
    totalPropertyDetail,
    portfolioValuation: portfolioValue,
    averageYield: convertToFloat(averageYield / arrayLength, 1),
    monthlyRent: portfolioMonthlyRent,
    currentDebt: totalCurrentDebt,
    monthlyRepayment: convertToInt(totalMonthlyRepayment),
    averageRent: convertToFloat(portfolioMonthlyRent / arrayLength, 1),
  };
};

const setChartTimeRange = (chartRange, chartType) => {
  switch (chartRange) {
    case "one-year":
      return "1 yrs";
    case "three-year":
      return "3 yrs";

    case "five-year":
      return "5 yrs";

    default:
      return chartType === "Suburb Average" ? "15 yrs" : "5 yrs";
  }
};

const Property = () => {
  const isDesktopSmall = useIsDesktopSmall();
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedMobileChartRange, setSelectedMobileChartRange] = useState(
    "one-year"
  );
  const propertyList = useDetailPropertyList() || initialPropertyList;
  const selectedProperty = useMemo(() => {
    return (
      propertyList?.find(
        (property) => property.propertyId === selectedPropertyId
      ) || {}
    );
  }, [propertyList, selectedPropertyId]);

  const isTablet = useIsTablet();
  const selectedPropertyContextValue = useMemo(() => {
    return { selectedPropertyId, setSelectedPropertyId, selectedProperty };
  }, [selectedPropertyId, setSelectedPropertyId, selectedProperty]);

  const totalMarketIndices = useTotalMarketIndices();
  const { data: marketIndices } = useMarketIndices();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = (value) => {
    setDialogOpen(value);
  };

  const handleSetMobileChartRange = (event, value) => {
    setSelectedMobileChartRange(value);
  };

  const {
    addressDetails = {},
    propertyEstimateHistory,
    propertyType = "",
    propertyId = "",
    purpose = "",
    estimate = "",
    comparableSales = [],
    monthlyRent = 0,
  } = selectedProperty || {};

  const {
    locality = "",
    postcode = "",
    state = "",
    street = "",
    streetNumber = "",
    unitNumber = "",
    streetNumberHigh = "",
  } = addressDetails;

  const marketData = useMemo(
    () => buildMarketData({ marketIndices, propertyType, locality, postcode }),
    [locality, postcode, marketIndices, propertyType]
  );

  const { marketDates, marketPrices, marketIndicatorData = 0 } = marketData;

  const currentMarketData = marketPrices.reduce((result, marketItem) => {
    return marketItem || result;
  }, 0);

  const saleHistoryChartData = useMemo(
    () => buildSaleHistoryChartData({ marketDates, marketPrices }),
    [marketDates, marketPrices]
  );

  const propertyDebtList = usePropertyDebtList();

  const totalPropertyDebtData = useTotalPropertyDebtData();
  const totalPropertyDetail = useTotalPropertyDetail();

  const selectedPropertyDebtData = useMemo(
    () =>
      propertyDebtList.find((debt) => debt.propertyId === propertyId) || {
        currentDebt: 0,
        monthlyRepayment: 0,
        propertyId,
        history: {},
      },
    [propertyDebtList, propertyId]
  );

  const propertyItemData = useMemo(
    () =>
      buildPropertyItemData({
        selectedPropertyDebtData,
        selectedProperty,
      }),
    [selectedPropertyDebtData, selectedProperty]
  );

  const isError = !!propertyList.find((property) => property.isError);

  useEffect(() => {
    if (propertyList.length === 0) {
      setSelectedPropertyId("");
      setDialogOpen(false);
    }
    // whenever the property list changes , correct the selection
    const propertyIds =
      propertyList.map((property) => property.propertyId) || [];
    const selectableIds = propertyIds.length
      ? [...propertyIds, "combined"]
      : [];
    const lastSelectedHasBeenDeleted = !selectableIds.includes(
      selectedPropertyId
    );
    if (!selectedPropertyId || lastSelectedHasBeenDeleted) {
      setSelectedPropertyId(selectableIds[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyList]);

  const address = `${unitNumber ? `${unitNumber} / ` : ""}${streetNumber}${
    streetNumberHigh ? ` - ${streetNumberHigh}` : ""
  } ${street} Street,`;
  const suburb = `${titleCase(locality)}, ${state}, ${postcode}`;

  const propertyPortfolio = propertyList.reduce((total, item, index, array) => {
    const {
      estimate: itemEstimate = 0,
      monthlyRent: itemMonthlyRent,
      percentRentYieldEstimate: itemYield,
    } = item;
    if (!total.estimate) {
      total.estimate = 0;
    }
    total.estimate += itemEstimate;
    if (!total.monthlyRent) {
      total.monthlyRent = 0;
    }
    total.monthlyRent += itemMonthlyRent;
    if (!total.averageYield) {
      total.averageYield = 0;
    }
    total.averageYield += itemYield;
    if (!total.arrayLength) {
      total.arrayLength = 1;
    }
    total.arrayLength = array.length;
    return total;
  }, {});

  const combinedPropertyCashflowPosition = useMemo(
    () =>
      buildCombinedPropertyCashflowPosition({
        totalPropertyDebtData,
        propertyPortfolio,
        totalPropertyDetail,
      }),
    [totalPropertyDebtData, propertyPortfolio, totalPropertyDetail]
  );

  const filteredChartData = useMemo(
    () =>
      buildFilteredPropertyChart(
        propertyEstimateHistory,
        selectedMobileChartRange
      ),
    [propertyEstimateHistory, selectedMobileChartRange]
  );

  const filteredSuburbChartData = useMemo(
    () =>
      buildSuburbFilteredData(saleHistoryChartData, selectedMobileChartRange),
    [saleHistoryChartData, selectedMobileChartRange]
  );

  const chartSliderdata = [
    {
      title: "Property EST",
      chartData: [
        {
          label: "Property Estimate",
          data: filteredChartData,
        },
        {
          label: "Suburb Average",
          data: filteredSuburbChartData,
          color: "#C0C0C0",
        },
      ],
      titleValue: estimate,
    },
  ];
  return (
    <SelectedPropertyContext.Provider value={selectedPropertyContextValue}>
      <HandleDialogContext.Provider value={{ setDialogOpen }}>
        <PropertyMontlyRentContext.Provider
          value={{
            monthlyRent: propertyItemData.propertyAttribute.monthlyRent,
            monthlyRepayment:
              propertyItemData.propertyAttribute.monthlyRepayment,
          }}
        >
          <div className="page-container">
            {propertyList.length > 0 && !isDesktopSmall && (
              <LinkedProperty
                propertyAttribute={propertyItemData.propertyAttribute}
                isError={isError}
                title="Property Portfolio"
                value={propertyPortfolio.estimate}
              />
            )}

            <PageBody>
              {!isTablet && <HeaderComponent />}
              {propertyList.length <= 0 ? (
                <>
                  <ConnectBankModal
                    isPropertyPage
                    videoBackground={propertyVideo}
                    callToActionButton={<DefaultAddProperty />}
                  />
                </>
              ) : (
                <>
                  <PageBodyContent className="property-details">
                    {!isTablet && propertyList.length > 0 && (
                      <div className="property-details__property-address">
                        {selectedPropertyId !== "combined"
                          ? `${titleCase(address)} ${suburb}`
                          : ""}
                      </div>
                    )}
                    {isDesktopSmall && (
                      <LinkedPropertyCarousel
                        propertyAttribute={propertyItemData.propertyAttribute}
                        isError={isError}
                      />
                    )}
                    {selectedPropertyId === "combined" ? (
                      <CombinedPropertyComponent
                        saleHistoryChartData={totalMarketIndices}
                        cashflowPosition={combinedPropertyCashflowPosition}
                        sliderSetting={mobileChartSliderSettings}
                        setChartTimeRange={setChartTimeRange}
                        currentMarketData={currentMarketData}
                        selectedMobileChartRange={selectedMobileChartRange}
                      />
                    ) : (
                      <>
                        {!isTablet ? (
                          <>
                            <div className="property-growth-valuation">
                              <MixedChartComponent
                                propertyEstimateHistory={
                                  propertyEstimateHistory
                                }
                                saleHistoryChartData={saleHistoryChartData}
                                debtChartData={propertyItemData.debtChartData}
                              />
                            </div>
                            <PropertyValuationComponent
                              propertyAttribute={
                                propertyItemData.propertyAttribute
                              }
                            />
                            {purpose === PROPERTY_PURPOSE.INVEST && (
                              <div className="d-flex outline-box-group flex-wrap">
                                <PerformanceScoreCardComponent
                                  performanceScore={
                                    propertyItemData.performanceScoreCard
                                  }
                                />
                                <CashflowPositionComponent
                                  cashflowData={propertyItemData.cashflowData}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="property-growth-valuation">
                              <div className="mobile-chart-container fullwidth-chart-container">
                                <Slider
                                  {...mobileChartSliderSettings}
                                  className="property-detail-slider"
                                >
                                  {chartSliderdata.map((chartSliderItem) => (
                                    <LineChartMobileWrapper
                                      key={chartSliderItem.title}
                                      chartTitle={chartSliderItem.title}
                                      titleValue={chartSliderItem.titleValue}
                                      chartData={chartSliderItem.chartData}
                                    />
                                  ))}
                                </Slider>
                                <DateFilter
                                  value={selectedMobileChartRange}
                                  onChange={handleSetMobileChartRange}
                                />
                              </div>
                            </div>
                            <Slider
                              {...mobileSliderSettings}
                              className="property-detail-slider"
                            >
                              <>
                                <div className="property-card-title">
                                  Property Valuation & Debt Position
                                </div>
                                <div className="card-container">
                                  <div className="property-value-attribute-container">
                                    <PropertyValueAttribute
                                      propertyAttribute={
                                        propertyItemData.propertyAttribute
                                      }
                                    />
                                    <PropertyValuePriceEstimate
                                      propertyAttribute={
                                        propertyItemData.propertyAttribute
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                              <>
                                <div className="property-card-title">
                                  Property Valuation & Debt Position
                                </div>
                                <div className="card-container">
                                  <div className="property-value-attribute-container">
                                    <PropertyDebtPosition
                                      propertyAttribute={
                                        propertyItemData.propertyAttribute
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                              <>
                                <div className="property-card-title">
                                  Performance Score Card
                                </div>
                                <div className="card-container">
                                  <div className="property-value-attribute-container">
                                    <PerformanceScoreCard
                                      dollar
                                      firstTitle="Medium Suburb Price"
                                      secondTitle="Current Price Estimate"
                                      firstValue={
                                        propertyItemData.performanceScoreCard
                                          .medianPrice
                                      }
                                      secondValue={
                                        propertyItemData.performanceScoreCard
                                          .estimate
                                      }
                                    />
                                    <PerformanceScoreCard
                                      dollar
                                      firstTitle="Average Monthly Rent"
                                      secondTitle="Current Monthly Rent"
                                      firstValue={
                                        propertyItemData.performanceScoreCard
                                          .averageMonthlyRent
                                      }
                                      secondValue={monthlyRent}
                                    />
                                  </div>
                                </div>
                              </>
                              <>
                                <div className="property-card-title">
                                  Cashflow Position
                                </div>
                                <div className="card-container">
                                  <div className="property-value-attribute-container">
                                    <CashflowPositionMobileComponent
                                      cashflowData={
                                        propertyItemData.cashflowData
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            </Slider>
                          </>
                        )}

                        <div className="market-indicator-container">
                          <div className="market-indicator-title">
                            {`Market Indicator for ${titleCase(locality)}`}
                          </div>
                          <MarketIndicatorProgressItem
                            firstTitle="Buyers Market"
                            secondTitle="Sellers Market"
                            value={marketIndicatorData}
                          />
                        </div>
                        <ComparablePropertiesContainer
                          propertyListing={comparableSales}
                        />
                        {(propertyList.length > 0 && !isError) ||
                          (propertyList.length === 0 && (
                            <div className="property-nodata">
                              <div className="nodata-img" />
                            </div>
                          ))}
                      </>
                    )}
                  </PageBodyContent>
                  <RemovePropertyComponent
                    open={dialogOpen}
                    handleDialogClose={handleOpenDialog}
                  />
                </>
              )}
            </PageBody>
          </div>
        </PropertyMontlyRentContext.Provider>
      </HandleDialogContext.Provider>
    </SelectedPropertyContext.Provider>
  );
};

export default Property;
