import PropTypes from "prop-types";
import React, { useContext } from "react";
import HandleDialogContext from "../../context/handle-dialog-context";

const RemovePropertyButton = ({ handleEditDialogToggle = null }) => {
  const { setDialogOpen } = useContext(HandleDialogContext);
  const handleDialogToggle = (value) => {
    handleEditDialogToggle(false);
    setDialogOpen(value);
  };
  return (
    <>
      <div className="w-100" onClick={() => handleDialogToggle(true)}>
        <div>Delete Property</div>
      </div>
    </>
  );
};

RemovePropertyButton.propTypes = {
  handleEditDialogToggle: PropTypes.func,
};

export default React.memo(RemovePropertyButton);
