import React from "react";
import PropTypes from "prop-types";
import MarketTrendItem from "./market-trend-item";
import { convertToFloat } from "../../utils/convert-number";

const PerformanceScoreCard = ({
  firstTitle = "",
  secondTitle = "",
  firstValue = 0,
  secondValue = 0,
  thirdTitle = "",
  dollar = false,
  percent = false,
  formatNumber = false,
}) => {
  const thirdValue = convertToFloat(secondValue - firstValue, 2);
  return (
    <div className="performance-score-card-item-container">
      <MarketTrendItem
        title={firstTitle}
        value={firstValue}
        dollar={dollar}
        percent={percent}
        formatNumber={formatNumber}
        center
      />
      <MarketTrendItem
        title={secondTitle}
        value={secondValue}
        dollar={dollar}
        percent={percent}
        formatNumber={formatNumber}
        center
        fieldName={
          secondTitle.includes("Monthly Repayment")
            ? "monthlyRepayment"
            : "monthlyRent"
        }
      />
      <div className="text-divider" />
      <MarketTrendItem
        title={`${
          thirdTitle || (thirdValue > 0 ? "Above Market" : "Below Market")
        }`}
        value={
          thirdTitle === "Excess Income" && thirdValue > 0
            ? 0
            : Math.abs(thirdValue)
        }
        dollar={dollar}
        percent={percent}
        formatNumber={formatNumber}
        center
      />
    </div>
  );
};

PerformanceScoreCard.propTypes = {
  firstTitle: PropTypes.string,
  secondTitle: PropTypes.string,
  firstValue: PropTypes.number,
  secondValue: PropTypes.number,
  thirdTitle: PropTypes.string,
  dollar: PropTypes.bool,
  percent: PropTypes.bool,
  formatNumber: PropTypes.bool,
};

export default PerformanceScoreCard;
