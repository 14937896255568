import PropTypes from "prop-types";
import React from "react";
import OutlinedDiv from "../../components/out-line-div";
import PropertyDebtPosition from "./property-debt-position";
import PropertyValuation from "./property-valuation";

const PropertyValuationComponent = ({ propertyAttribute = {} }) => {
  return (
    <OutlinedDiv label="Property Valuation & Debt Position">
      <div className="performance-score-card">
        <PropertyValuation propertyAttribute={propertyAttribute} />
        <PropertyDebtPosition propertyAttribute={propertyAttribute} />
      </div>
    </OutlinedDiv>
  );
};

PropertyValuationComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  propertyAttribute: PropTypes.object,
};

export default PropertyValuationComponent;
