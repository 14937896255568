import { Dialog } from "@material-ui/core";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import UpdateProperty from "../../components/PropertyModal/update-property";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { useUpdatePropertyMutation } from "../../hooks/property.hooks";

const UpdatePropertyModal = ({
  open = false,
  handleDialogClose = null,
  propertyAttribute = {},
}) => {
  const { selectedPropertyId } = useContext(SelectedPropertyContext);
  const [params, setParams] = useState({});

  useEffect(() => {
    const {
      bathrooms = 0,
      bedrooms = 0,
      carparks = 0,
      landarea = 0,
      propertyType,
      purpose,
    } = propertyAttribute;

    setParams({
      bathrooms,
      bedrooms,
      carparks,
      landarea,
      propertyType,
      purpose,
    });
  }, [propertyAttribute]);
  const updateProperty = useUpdatePropertyMutation();
  const { enqueueSnackbar } = useSnackbar();
  const handleUpdateProperty = () => {
    updateProperty.mutate(
      {
        params,
        id: selectedPropertyId,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Update property successfully", {
            variant: "success",
          });
          handleDialogClose();
        },
      }
    );
  };

  const handleCloseModal = () => {
    handleDialogClose(false);
  };

  const handleUpdateParams = (value) => {
    setParams((prevParams) => ({ ...prevParams, ...value }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
      className="property-modal"
    >
      <div className="property-modal__container">
        <UpdateProperty
          params={params}
          handleUpdateParams={handleUpdateParams}
          handleUpdateProperty={handleUpdateProperty}
          isLoading={updateProperty.isLoading}
        />
      </div>
    </Dialog>
  );
};

UpdatePropertyModal.propTypes = {
  open: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  propertyAttribute: PropTypes.object,
};

export default UpdatePropertyModal;
