import PropTypes from "prop-types";
import React from "react";
import DoughnutChartComponent from "../../components/doughnut-chart-component";
import { convertToInt } from "../../utils/convert-number";
import PerformanceScoreCard from "./performance-score-card-item";

const CashflowPositionMobileComponent = ({ cashflowData = {} }) => {
  const { monthlyRent = 0, monthlyRepayment = 0 } = cashflowData;
  const totalValue = monthlyRent + monthlyRepayment;
  const chartPercent = (monthlyRent / totalValue) * 100;
  const chartData = {
    data: [
      monthlyRent,
      monthlyRepayment === 0 && monthlyRent === 0 ? 1 : monthlyRepayment,
    ],
    title: ["Rental Income", "Repayment"],
  };
  return (
    <>
      <DoughnutChartComponent
        title={`Cashflow ${
          monthlyRent > monthlyRepayment ? "Positive" : "Negative"
        }`}
        data={chartData}
        textTop={`${convertToInt(chartPercent)}%`}
        textBottom="Rental Income"
      />
      <PerformanceScoreCard
        dollar
        firstTitle="Monthly Rent"
        secondTitle="Monthly Repayment"
        thirdTitle="Excess Income"
        firstValue={monthlyRent}
        secondValue={cashflowData.monthlyRepayment}
      />
    </>
  );
};

CashflowPositionMobileComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cashflowData: PropTypes.object,
};

export default CashflowPositionMobileComponent;
