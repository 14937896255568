import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import HeaderContent from "../../components/header-content";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { useLinkedBankAccountData } from "../../hooks/account.hooks";
import { useFinanceSummary } from "../../hooks/common.hooks";
import { usePropertyList } from "../../hooks/property.hooks";
import { formatNumberWithDollar } from "../../utils/numberFormater";
import AddPropertyButton from "./add-property-button";
import LinkedPropertyAccount from "./linked-property-account";

const initialPropertyList = [];

const LinkedProperty = ({
  title = "",
  value = 0,
  propertyAttribute,
  isError = false,
}) => {
  const [accountLoanList, setAccountLoanList] = useState("");
  const linkedBankAccountData = useLinkedBankAccountData() || {};
  const { selectedPropertyId, setSelectedPropertyId } = useContext(
    SelectedPropertyContext
  );
  const { data: propertyList = initialPropertyList } = usePropertyList();
  const summary = useFinanceSummary() || {};
  const { propertyPortfolio = 0 } = summary;
  const { loan: loans = [] } = linkedBankAccountData;

  useEffect(() => {
    const checkPropertyAvailable = propertyList.find(
      (item) => selectedPropertyId === item.propertyId
    );
    if (propertyList.length > 0 && selectedPropertyId !== "combined") {
      selectedPropertyId !== propertyList[0].propertyId &&
        !checkPropertyAvailable &&
        setSelectedPropertyId(propertyList[0].propertyId);
    }
  }, [propertyList, selectedPropertyId, setSelectedPropertyId]);
  useEffect(() => {
    if (propertyList.length > 0) {
      const accountLoan = propertyList
        .map((item) => item.accountIds || "")
        .join("");
      setAccountLoanList(accountLoan);
    } else {
      setAccountLoanList("");
    }
  }, [propertyList]);

  const handleCombinedSelect = () => {
    setSelectedPropertyId("combined");
  };
  return (
    <div className="da-body-linked-account">
      {loans.length === 0 && propertyList.length === 0 && <AddPropertyButton />}
      {(loans.length > 0 || propertyList.length > 0) && (
        <div className="linked-property linked-account-section">
          <div className="linked-account-header la-container">
            <HeaderContent
              name={title}
              value={value}
              display={
                !(
                  (propertyList.length > 0 && isError) ||
                  propertyList.length === 0
                )
              }
            />
          </div>

          {(propertyList.length > 0 && isError) ||
            (propertyList.length > 1 && (
              <>
                <div className="linked-account-header la-container">
                  Property
                </div>
                <div
                  className={`la-container combined-value is-cursor ${
                    selectedPropertyId === "combined" ? "selected" : ""
                  }`}
                  onClick={handleCombinedSelect}
                >
                  {" "}
                  <div className="linked-account-wrapper">
                    <div className="linked-account-item-title">Combined</div>
                    <span className="linked-account-item-value">
                      {formatNumberWithDollar(propertyPortfolio)}
                    </span>
                  </div>
                </div>
              </>
            ))}
          {propertyList.length > 0 &&
            propertyList.map((property) => {
              const {
                addressDetails = {},
                accountIds = "",
                isError: isPropertyError = false,
                propertyId = "",
              } = property;
              if (isPropertyError) return null;
              let loanListData = [];
              if (accountIds) {
                loanListData = loans.reduce((loanList = [], loanItem) => {
                  const { accountId = "" } = loanItem;
                  if (accountIds.indexOf(accountId) !== -1) {
                    loanList.push(loanItem);
                  }
                  return loanList;
                }, []);
              }
              return (
                <LinkedPropertyAccount
                  addressDetails={addressDetails}
                  key={propertyId}
                  loanListData={loanListData}
                  propertyId={propertyId}
                  propertyAttribute={propertyAttribute}
                  isProperty
                  cursor
                />
              );
            })}
          {loans
            .filter(
              (item) => accountLoanList.indexOf(item.accountId || "") === -1
            )
            .map((item) => {
              return (
                <LinkedPropertyAccount
                  key={item.accountId}
                  accountId={item.accountId}
                  loanListData={[item]}
                />
              );
            })}
          <AddPropertyButton />
        </div>
      )}
    </div>
  );
};

LinkedProperty.propTypes = {
  propertyAttribute: PropTypes.object,
};

export default LinkedProperty;
