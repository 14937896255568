import React from "react";
import NumberFormat from "react-number-format";
import MarketTrendItem from "./market-trend-item";

const PropertyValuePriceEstimate = ({ propertyAttribute }) => {
  const {
    confidence = "",
    estimate = 0,
    estimateHigh = 0,
    estimateLow = 0,
    medianPrice = 0,
    purpose = "",
  } = propertyAttribute;
  const comparePrice = estimate - medianPrice;
  return (
    <div className="property-value-price-estimate">
      <div className="property-value-title">Price Estimate</div>
      <div className="price-estimate-value">
        <div>
          <NumberFormat
            value={estimateLow}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </div>
        <div className="font-weight-bold">
          <NumberFormat
            value={estimate}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </div>
        <div>
          <NumberFormat
            value={estimateHigh}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </div>
      </div>
      <span className="price-estimate-confidence">{confidence} Confidence</span>
      {purpose === "OCCUPY" && (
        <MarketTrendItem
          title={`${comparePrice > 0 ? "Above" : "Below"} Suburb Medium`}
          value={Math.abs(comparePrice)}
          dollar
          center
        />
      )}
    </div>
  );
};

export default PropertyValuePriceEstimate;
