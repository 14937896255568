import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import Slider from "react-slick";
import { AngleLeft, AngleRight, NoImage } from "../../images";
import { monthDayNameFormater } from "../../utils/calendar-util";
import ComparablePropertiesItemIconGroup from "./comparable-properties-item-icon-group";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={AngleRight} alt="experity-angle-right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={AngleLeft} alt="experity-angle-left" />
    </div>
  );
}
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  autoplaySpeed: 3000,
  fadeIn: false,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  adaptiveHeight: false,
  lazyLoad: true,

  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
const ComparablePropertiesItemComponent = ({
  image = [],
  address = "",
  value = 0,
  propertyAttribute = {},
  saleDate = "",
}) => {
  return (
    <div className="comparable-properties-item">
      <div className="comparable-properties-item-photo">
        <Slider {...settings}>
          {image.length > 0 ? (
            image.map((item) => {
              const { url = "" } = item;
              return (
                <img key={url} src={url} alt="experity-comparable-property" />
              );
            })
          ) : (
            <img src={NoImage} alt="experity-comparable-property" />
          )}
        </Slider>
      </div>
      <div className="comparable-properties-item-address">{address}</div>
      <div className="text-divider" />
      <div className="comparable-properties-item-desc">
        {monthDayNameFormater(new Date(saleDate))} Sold For:
      </div>
      <div className="comparable-properties-item-value">
        <NumberFormat
          value={value || 0}
          displayType="text"
          thousandSeparator
          prefix="$"
        />
      </div>
      <ComparablePropertiesItemIconGroup
        propertyAttribute={propertyAttribute}
      />
    </div>
  );
};

ComparablePropertiesItemComponent.propTypes = {
  image: PropTypes.array,
  address: PropTypes.string,
  saleDate: PropTypes.string,
  value: PropTypes.number,
  propertyAttribute: PropTypes.object,
};

export default React.memo(ComparablePropertiesItemComponent);
