import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MarketTrendItem from "./market-trend-item";
import { isNotEmptyObject } from "../../utils/object-util";
import DoughnutChartComponent from "../../components/doughnut-chart-component";
import { convertToInt } from "../../utils/convert-number";

const PropertyDebtPosition = ({ propertyAttribute }) => {
  const {
    estimate = 0,
    currentDebt = 0,
    monthlyRepayment = 0,
  } = propertyAttribute;

  const chartData = useMemo(() => {
    const equityValue = estimate - currentDebt;
    const chartPercent = (currentDebt / estimate) * 100;
    return {
      data: [equityValue, currentDebt],
      title: ["Equity", "Debt"],
      chartPercent,
    };
  }, [currentDebt, estimate]);
  return (
    <>
      <div className="property-value-currendt-debt">
        <div className="property-value-title">Current Debt</div>
        <MarketTrendItem
          title="Current Debt"
          value={currentDebt}
          dollar
          center
        />
        <MarketTrendItem
          title="Monthly Repayment"
          fieldName="monthlyRepayment"
          value={monthlyRepayment}
          dollar
          center
        />
      </div>
      <div className="property-value-chart">
        {isNotEmptyObject(chartData) && (
          <DoughnutChartComponent
            title="LVR"
            data={chartData}
            textTop={`${convertToInt(chartData.chartPercent)}%`}
            textBottom="LVR"
          />
        )}
      </div>
    </>
  );
};

PropertyDebtPosition.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  propertyAttribute: PropTypes.object,
};

export default PropertyDebtPosition;
