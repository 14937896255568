import React from "react";
import PropTypes from "prop-types";
import PriceEstimateIcon from "./price-estimate-icon";
import Bedroom from "../../svgs/Bedroom";
import Car from "../../svgs/Car";
import Bathroom from "../../svgs/Bathroom";
import LandArea from "../../svgs/LandArea";

const ComparablePropertiesItemIconGroup = ({ propertyAttribute = {} }) => {
  const {
    bathrooms = 0,
    bedrooms = 0,
    carparks = 0,
    landarea = 0,
  } = propertyAttribute;
  return (
    <div className="comparable-properties-item-icon-group">
      <PriceEstimateIcon logo={<Bedroom />} number={bedrooms} />
      <PriceEstimateIcon logo={<Bathroom />} number={bathrooms} />
      <PriceEstimateIcon logo={<Car />} number={carparks} />
      <PriceEstimateIcon logo={<LandArea />} number={landarea} />
    </div>
  );
};

ComparablePropertiesItemIconGroup.propTypes = {
  propertyAttribute: PropTypes.object,
};

export default ComparablePropertiesItemIconGroup;
