import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { useLinkedBankAccountData } from "../../hooks/account.hooks";
import { useFinanceSummary } from "../../hooks/common.hooks";
import { usePropertyList } from "../../hooks/property.hooks";
import { convertToFloat } from "../../utils/convert-number";
import { formatNumberWithDollar } from "../../utils/numberFormater";
import AddPropertyButton from "./add-property-button";
import LinkedPropertyAccount from "./linked-property-account";

const LinkedPropertyCarousel = ({ propertyAttribute }) => {
  const [accountLoanList, setAccountLoanList] = useState("");
  const linkedBankAccountData = useLinkedBankAccountData() || {};
  const { selectedPropertyId, setSelectedPropertyId } = useContext(
    SelectedPropertyContext
  );
  const { data: propertyList } = usePropertyList();
  const summary = useFinanceSummary() || {};
  useEffect(() => {
    const checkPropertyAvailable = propertyList?.find(
      (item) => selectedPropertyId === item.propertyId
    );
    const ableSelectProperty =
      propertyList?.length > 0 &&
      !checkPropertyAvailable &&
      selectedPropertyId !== "combined" &&
      selectedPropertyId !== propertyList[0].propertyId;
    if (ableSelectProperty) {
      setSelectedPropertyId(propertyList[0].propertyId);
    }
  }, [propertyList]);
  useEffect(() => {
    const accountLoan =
      propertyList && propertyList.length > 0
        ? propertyList.map((item) => item.accountIds || "").join("")
        : "";
    setAccountLoanList(accountLoan);
  }, [propertyList]);

  const handleCombinedSelect = () => {
    setSelectedPropertyId("combined");
  };
  const { propertyPortfolio = 0 } = summary;
  const { loan: loans = [] } = linkedBankAccountData;

  const linkedPropertyAccounts =
    propertyList
      ?.filter((property) => !property.isError)
      .map((property) => {
        const {
          addressDetails = {},
          accountIds = "",
          propertyId = "",
        } = property;
        const loanListData = accountIds
          ? loans.filter(
              (loan) => accountIds.indexOf(loan.accountId || "") !== -1
            )
          : [];

        return {
          addressDetails,
          propertyId,
          loanListData,
          propertyAttribute, // propertyAttribute is defined as above?
        };
      }) || [];
  return (
    <ScrollContainer horizontal className="scroll-container">
      <div className="linked-property linked-account-section">
        {propertyList?.length > 0 && (
          <div className="linked-account-header">Properties</div>
        )}
        <div className="d-flex">
          {propertyList?.length > 0 && (
            <>
              {propertyList.length > 1 && (
                <div
                  className={`la-container is-cursor ${
                    selectedPropertyId === "combined" ? "selected" : ""
                  }`}
                  onClick={handleCombinedSelect}
                >
                  <div className="linked-account-wrapper">
                    <div className="linked-account-item-title">Combined</div>
                    <span className="linked-account-item-value">
                      {formatNumberWithDollar(
                        convertToFloat(propertyPortfolio, 2)
                      )}
                    </span>
                  </div>
                </div>
              )}

              {linkedPropertyAccounts.map((item) => (
                <LinkedPropertyAccount
                  key={`property${item?.propertyId}`}
                  {...item}
                  isProperty
                  cursor
                />
              ))}
            </>
          )}
          {loans.length > 0 &&
            loans
              .filter(
                (item) => accountLoanList.indexOf(item.accountId || "") === -1
              )
              .map((item) => (
                <LinkedPropertyAccount
                  key={`propertyLoan${item?.accountId}`}
                  accountId={item.accountId}
                  loanListData={[item]}
                />
              ))}
          <AddPropertyButton />
        </div>
      </div>
    </ScrollContainer>
  );
};

LinkedPropertyCarousel.propTypes = {
  propertyAttribute: PropTypes.object,
};

export default React.memo(LinkedPropertyCarousel);
