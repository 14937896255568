import PropTypes from "prop-types";
import React from "react";
import PropertyValueAttribute from "./property-value-attribute";
import PropertyValuePriceEstimate from "./property-value-price-estimate";

const PropertyValuation = ({ propertyAttribute = {} }) => {
  return (
    <>
      <PropertyValueAttribute propertyAttribute={propertyAttribute} />
      <PropertyValuePriceEstimate propertyAttribute={propertyAttribute} />
    </>
  );
};

PropertyValuation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  propertyAttribute: PropTypes.object,
};

export default PropertyValuation;
