import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import EditableTextField from "./editable-text-field";

const MarketTrendItem = ({
  title = "",
  value = 0,
  dollar = false,
  percent = false,
  center = false,
  fieldName = "monthlyRent",
}) => {
  return (
    <div className={`market-trend-item ${center ? "text-center" : ""}`}>
      <div className="market-trend-item-title">{title}</div>
      <div className="market-trend-item-value">
        {["Monthly Rent", "Monthly Repayment", "Current Monthly Rent"].includes(
          title
        ) && title.indexOf("Average") !== 0 ? (
          <EditableTextField fieldName={fieldName} />
        ) : (
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            prefix={dollar ? "$" : ""}
            suffix={percent ? "%" : ""}
          />
        )}
      </div>
    </div>
  );
};

MarketTrendItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  dollar: PropTypes.bool,
  percent: PropTypes.bool,
};

export default MarketTrendItem;
