import React from "react";
import { Grid } from "@material-ui/core";

import MobileTabs from "../../components/mobile-tab";

export const dateFilterTabs = [
  {
    title: "1 year",
    value: "one-year",
  },
  {
    title: "3 years",
    value: "three-year",
  },
  {
    title: "5 years",
    value: "five-year",
  },
  {
    title: "All",
    value: "all",
  },
];

const DateFilter = (props) => {
  const { value, onChange } = props;
  return (
    <Grid
      justify="center"
      style={{ display: "flex", marginTop: 8, width: "100%" }}
    >
      <MobileTabs value={value} onChange={onChange} tabs={dateFilterTabs} />
    </Grid>
  );
};

export default DateFilter;
