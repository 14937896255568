import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { dateFilterTabs } from "../pages/property/date-filter";

const ChartYearFilter = ({
  selectedIndex = 0,
  setSelectedIndex = () => {},
  selectedEstimate = 0,
  setSelectedEstimate = () => {},
  isEstimate = false,
}) => {
  const handleSelectFilter = (value) => {
    setSelectedIndex(value);
  };

  const handleSelectEstimate = (value) => {
    setSelectedEstimate(value);
  };
  return (
    <div className="chart-year-filter">
      <div className="chart-year-filter-group">
        {["Property Estimate", "Suburb Average", "Combined"].map(
          (group, index) => (
            <div
              key={group}
              onClick={() => handleSelectEstimate(index)}
              className={classNames("chart-year-filter-item", {
                selected: selectedEstimate === index,
                "d-none": !isEstimate,
              })}
            >
              {group}
            </div>
          )
        )}
      </div>
      <div className="chart-year-filter-group">
        {dateFilterTabs
          .map((e) => e.title)
          .map((year, index) => (
            <div
              key={year}
              onClick={() => handleSelectFilter(index)}
              className={classNames("chart-year-filter-item", {
                selected: selectedIndex === index,
              })}
            >
              {year}
            </div>
          ))}
      </div>
    </div>
  );
};

ChartYearFilter.propTypes = {
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
};

export default ChartYearFilter;
