import PropTypes from "prop-types";
import React, { useState } from "react";
import UpdatePropertyModal from "./update-property-modal";

const UpdatePropertyButton = ({
  propertyAttribute,
  handleEditDialogToggle = null,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogToggle = (value) => {
    handleEditDialogToggle(false);
    setDialogOpen(value);
  };
  return (
    <>
      <div className="w-100" onClick={() => handleDialogToggle(true)}>
        <div>Update Property</div>
      </div>
      <UpdatePropertyModal
        open={dialogOpen}
        handleDialogClose={handleDialogToggle}
        propertyAttribute={propertyAttribute}
      />
    </>
  );
};

UpdatePropertyButton.propTypes = {
  propertyAttribute: PropTypes.object,
  handleEditDialogToggle: PropTypes.func,
};

export default UpdatePropertyButton;
