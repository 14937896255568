import React from "react";
import PropTypes from "prop-types";
import PerformanceScoreCard from "./performance-score-card-item";

const PerformanceScoreCardContainer = ({ performanceScore = {} }) => {
  const {
    medianPrice = 0,
    meanPercentRentalYield = 0,
    averageMonthlyRent = 0,
    estimate = 0,
    percentRentYieldEstimate = 0,
    monthlyRent = 0,
  } = performanceScore;
  return (
    <div className="performance-score-card-item">
      <PerformanceScoreCard
        dollar
        firstTitle="Medium Suburb Price"
        secondTitle="Current Price Estimate"
        firstValue={medianPrice}
        secondValue={estimate}
      />
      <PerformanceScoreCard
        percent
        firstTitle="Average Rental Yield"
        secondTitle="Rental Yield"
        firstValue={meanPercentRentalYield}
        secondValue={percentRentYieldEstimate}
      />
      <PerformanceScoreCard
        dollar
        firstTitle="Average Monthly Rent"
        secondTitle="Current Monthly Rent"
        firstValue={averageMonthlyRent}
        secondValue={monthlyRent}
      />
    </div>
  );
};

PerformanceScoreCardContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  performanceScore: PropTypes.object,
};

export default PerformanceScoreCardContainer;
