import React, { useMemo } from "react";
import Slider from "react-slick";
import DoughnutChartComponent from "../../components/doughnut-chart-component";
import { LineChartMobileWrapper } from "../../components/line-chart-mobile-wrapper";
import MixedChartComponent from "../../components/mixed-chart-component";
import { useIsTablet } from "../../hooks/common.hooks";
import {
  buildFilteredPropertyChart,
  buildSuburbFilteredData,
} from "../../utils/calculate-property-data-util";
import ComponentPropertyCashflowItem from "./combined-property-cashflow-item";

const CombinedPropertyComponent = ({
  saleHistoryChartData,
  cashflowPosition = {},
  sliderSetting = {},
  setChartTimeRange = () => {},
  selectedMobileChartRange,
}) => {
  const {
    totalDebtHistory = {},
    totalPropertyDetail,
    portfolioValuation = 0,
    averageYield = 0,
    monthlyRent = 0,
    currentDebt = 0,
    monthlyRepayment = 0,
    averageRent = 0,
  } = cashflowPosition;
  const totalDebt =
    (currentDebt / (portfolioValuation === 0 ? 1 : portfolioValuation)) * 100;
  const totalRentalRepayment =
    (monthlyRent / (monthlyRepayment + monthlyRent)) * 100;
  const equity = portfolioValuation - currentDebt;
  const debtChartData = {
    data: [equity, currentDebt],
    title: ["Equity", "Debt"],
  };
  const rentalChartData = {
    data: [monthlyRent, monthlyRepayment],
    title: ["Rental Income", "Repayment"],
  };

  const filteredTotalChartData = useMemo(
    () =>
      buildFilteredPropertyChart(totalPropertyDetail, selectedMobileChartRange),
    [totalPropertyDetail, selectedMobileChartRange]
  );
  const filteredSuburbChartData = useMemo(
    () =>
      buildSuburbFilteredData(saleHistoryChartData, selectedMobileChartRange),
    [saleHistoryChartData, selectedMobileChartRange]
  );

  const chartSliderdata = [
    {
      title: "Property EST",
      chartData: [
        {
          label: "Property Estimate",
          data: filteredTotalChartData,
        },
        {
          label: "Suburb Average",
          data: filteredSuburbChartData,
          color: "#C0C0C0",
        },
      ],
      titleValue: portfolioValuation,
    },
  ];

  const isTablet = useIsTablet();
  return (
    <div className="combined-property-container">
      <div className="combined-property-doughnut-chart-group">
        <DoughnutChartComponent
          title="Rental & Repayments"
          data={rentalChartData}
          textTop={parseInt(totalRentalRepayment, 10) || `${0}%`}
          textBottom="RVR"
        />

        <DoughnutChartComponent
          title="Debt & Equity"
          data={debtChartData}
          textTop={`${parseInt(totalDebt, 10)} %`}
          textBottom="LVR"
        />
      </div>
      <div className="combined-property-chart-container">
        {isTablet ? (
          <div className="property-growth-valuation">
            <div className="mobile-chart-container fullwidth-chart-container">
              <Slider {...sliderSetting} className="property-detail-slider">
                {chartSliderdata.map((chartSliderItem) => {
                  return (
                    <LineChartMobileWrapper
                      key={chartSliderItem.title}
                      chartTitle={chartSliderItem.title}
                      subTitle={`(Last ${setChartTimeRange(
                        selectedMobileChartRange,
                        chartSliderItem.title
                      )})`}
                      titleValue={chartSliderItem.titleValue}
                      chartData={chartSliderItem.chartData}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        ) : (
          <MixedChartComponent
            propertyEstimateHistory={totalPropertyDetail}
            saleHistoryChartData={saleHistoryChartData}
            debtChartData={totalDebtHistory}
          />
        )}
        <div className="combined-property-cashflow">
          <div className="combined-property-cashflow-title">
            Cashflow {monthlyRent > monthlyRepayment ? "Positive" : "Negative"}
          </div>
          <div className="combined-property-cashflow-group">
            <ComponentPropertyCashflowItem
              title="Portfolio Valuation"
              value={portfolioValuation}
            />
            <ComponentPropertyCashflowItem
              title="Current Debt"
              value={currentDebt}
            />
            <ComponentPropertyCashflowItem
              title="Monthly Repayments"
              value={monthlyRepayment}
            />
            <ComponentPropertyCashflowItem
              title="Monthly Rent"
              value={monthlyRent}
            />
            <ComponentPropertyCashflowItem
              title="Average Yield"
              value={averageYield}
              percent
            />
            <ComponentPropertyCashflowItem
              title="Average Rent"
              value={averageRent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CombinedPropertyComponent.propTypes = {};

export default React.memo(CombinedPropertyComponent);
