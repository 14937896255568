import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext, useRef, useState } from "react";
import CustomModalComponent from "../../components/custom-modal-component";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { useDeletePropertyMutation } from "../../hooks/property.hooks";
import { TrashBin } from "../../images";

const defaultContent = {
  header: "Delete Property?",
  body: "You'll permanently lose your property Data",
};

const RemovePropertyComponent = ({ open, handleDialogClose = null }) => {
  const { selectedPropertyId, setSelectedPropertyId } = useContext(
    SelectedPropertyContext
  );
  const deleteProperty = useDeletePropertyMutation();
  const [isDeletingProperty, setIsDeletingProperty] = useState(false);
  const [deleteDone, setdeleteDone] = useState(false);
  const [deleteContent, setDeleteContent] = useState(defaultContent);

  const timeoutHandles = useRef(false);

  const handleCloseModal = (e) => {
    e.stopPropagation();
    if (timeoutHandles.current) {
      clearTimeout(timeoutHandles.current);
    }
    setTimeout(() => {
      setdeleteDone(false);
      setDeleteContent(defaultContent);
    }, 500);

    handleDialogClose(false);
  };
  const handleDeleteProperty = () => {
    if (selectedPropertyId) {
      setIsDeletingProperty(true);
      deleteProperty
        .mutateAsync(selectedPropertyId)
        .then(() => {
          setSelectedPropertyId("");
          setdeleteDone(true);
          setDeleteContent({
            header: "Success!",
            body: "Your property was successfully deleted.",
          });
        })
        .catch((err) => {
          const { data = {} } = err;
          const { message = "" } = data;
          setDeleteContent({
            header: "Error!",
            body: message,
          });
        })
        .finally(() => {
          setIsDeletingProperty(false);
        });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
      className="custom-modal-container"
    >
      <CustomModalComponent
        header={deleteContent.header}
        description={deleteContent.body}
        handleCloseModal={handleCloseModal}
        state={deleteDone}
        isProcessing={isDeletingProperty}
        inputFuct={handleDeleteProperty}
        icon={TrashBin}
      />
    </Dialog>
  );
};

RemovePropertyComponent.propTypes = {
  open: PropTypes.bool,
  handleDialogClose: PropTypes.func,
};

export default React.memo(RemovePropertyComponent);
