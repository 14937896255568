import PropTypes from "prop-types";
import React from "react";
import OutlinedDiv from "../../components/out-line-div";
import PerformanceScoreCardContainer from "./performance-score-card-container";

const PerformanceScoreCardComponent = ({ performanceScore = {} }) => {
  return (
    <OutlinedDiv label="Performance Score Card">
      <PerformanceScoreCardContainer performanceScore={performanceScore} />
    </OutlinedDiv>
  );
};

PerformanceScoreCardComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  performanceScore: PropTypes.object,
};

export default PerformanceScoreCardComponent;
