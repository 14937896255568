import React from "react";
import PropTypes from "prop-types";

const PriceEstimateIcon = ({ logo, number = 0 }) => {
  return (
    <div className="price-estimate-icon-wrapper">
      <div className="price-estimate-icon">{logo}</div>
      {number}
    </div>
  );
};

PriceEstimateIcon.propTypes = {
  logo: PropTypes.node,
  number: PropTypes.number,
};

export default PriceEstimateIcon;
