import PropTypes from "prop-types";
import React, { useContext } from "react";
import { putProperty } from "../../api/property";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { useQueryInvalidationWithNotification } from "../../hooks";

const UnlinkProperty = ({ handleEditDialogToggle = () => {} }) => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  const { selectedPropertyId } = useContext(SelectedPropertyContext);
  const handleUnlinkProperty = async () => {
    const params = {
      accountIds: "",
    };
    await putProperty(params, selectedPropertyId);
  };
  const handleDialogToggle = async () => {
    await handleUnlinkProperty();
    handleEditDialogToggle(false);
    invalidateQueries("propertyList");
  };
  return (
    <div
      className="w-100"
      role="button"
      tabIndex="0"
      onKeyDown={() => {}}
      onClick={() => handleDialogToggle()}
    >
      <div>Unlink Property</div>
    </div>
  );
};

UnlinkProperty.propTypes = {
  handleEditDialogToggle: PropTypes.func,
};

export default UnlinkProperty;
